// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Col, Row } from 'antd';
import './SetupPageLayout.less';
import type React from 'react';
import { useEffect } from 'react';
import type { PropsWithChildren } from 'react';
import { useNavigateToNextStep } from '../hooks/useNavigateToNextStep';
import PageFooter from '../../components/layout/PageFooter';
import { getPathKey } from '../../utils/utils';
import { StorageKeys } from '../../api/constants';
import { STEPS } from '../constants';

type SetupPageLayoutProps = {
  title: string;
  graphic?: React.ReactNode;
};

const SetupPageLayout = ({ title, graphic, children }: PropsWithChildren<SetupPageLayoutProps>) => {
  const navigateToNextStep = useNavigateToNextStep();

  useEffect(() => {
    const pathKey = getPathKey(location.pathname);
    const tenantSetupState = sessionStorage.getItem(StorageKeys.TENANT_SETUP_STATE);
    console.debug('Setup State: ', tenantSetupState);
    if (!tenantSetupState || (tenantSetupState === 'completed' && pathKey !== 'setup.setup-complete')) {
      navigateToNextStep(STEPS.START);
    }
  }, [navigateToNextStep]);

  return (
    <div className="setup-window p-xxxl elevation-xl">
      <div className="setup-container">
        <Row>
          <Col sm={24} md={24} lg={graphic ? 14 : 24} className="setup-content p-xxxl">
            <h1 className="title">{title}</h1>
            {children}
          </Col>
          <Col sm={0} md={0} lg={graphic ? 10 : 0}>
            <div className="setup-graphic">
              <div>{graphic}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={'setup-footer'}>
        <PageFooter />
      </div>
    </div>
  );
};

export { SetupPageLayout };
