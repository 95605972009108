import { DPA_DOCUMENT_NAME } from '../setup/containers/data-processing-addendum/DataProcessingAddendum';
import { CommonHeaders, DpaStatus, Endpoints, StorageKeys } from './constants';
import type {
  CheckDpaConsentRequest,
  CheckDpaConsentResponse,
  ClientCreateRequest,
  ClientCreateResponse,
  FileAccessModifier,
  GetAadConsentRequest,
  GetAadConsentResponse,
  IdpMetadataSaveRequest,
  OrgPreferencesUpdateRequest,
  TenantSetupCompleteRequest,
} from './types';
import { fetcher } from './utils';
import { ErrorCode } from '../errorHandler/constants';
import { FatalError } from '../errorHandler/errors';

function icpMetadataSave(data: IdpMetadataSaveRequest) {
  return fetcher(Endpoints.ICP_METADATA_SAVE, {
    method: 'POST',
    body: JSON.stringify(data),
    workflowId: sessionStorage.getItem(StorageKeys.WORKFLOW_ID),
  });
}

function saveOrgInfo(data: OrgPreferencesUpdateRequest) {
  return fetcher(Endpoints.ORG_PREFERENCES_UPDATE, {
    method: 'POST',
    body: JSON.stringify(data),
    workflowId: sessionStorage.getItem(StorageKeys.WORKFLOW_ID),
  });
}

// TODO: We need to know how to get these from BE
const getIpRanges = async (): Promise<string[]> => {
  return ['44.207.16.175/32', '44.196.189.191/32', '34.196.47.118/32'];
};

const getAadConsentUrl = async (data: GetAadConsentRequest): Promise<GetAadConsentResponse> => {
  return fetcher(Endpoints.AAD_CONSENT_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    workflowId: sessionStorage.getItem(StorageKeys.WORKFLOW_ID),
  });
};

const createTenantSetupCompleteRequest = (withEntraId: boolean, dpa: boolean): TenantSetupCompleteRequest | null => {
  const email = localStorage.getItem(StorageKeys.ORG_EMAIL);

  if (!email) {
    console.log('Email not found in localStorage');
    throw new FatalError({ code: ErrorCode.FATAL_ERROR, message: 'Email not found in localStorage' });
  }

  return {
    email,
    withEntraId: withEntraId,
    consent: dpa ? {
      grantType: 'granted',
      documentName: DPA_DOCUMENT_NAME,
    } : undefined,
  };
};

const sendTenantSetupCompleteRequest = async (withEntraId: boolean): Promise<boolean> => {
  const dpa = sessionStorage.getItem(StorageKeys.DPA_STATUS) === DpaStatus.GRANTED;
  const request = createTenantSetupCompleteRequest(withEntraId, dpa);
  return fetcher(Endpoints.COMPLETE_SETUP, {
    method: 'POST',
    body: JSON.stringify(request),
    workflowId: sessionStorage.getItem(StorageKeys.WORKFLOW_ID),
  });
};

const startTenantSetup = async (bearerToken: string | null) => {
  if (!bearerToken) {
    throw new Error('Bearer token is missing');
  }
  return fetcher(Endpoints.START_TENANT_SETUP, {
    method: 'POST',
    body: JSON.stringify({ bearerToken: bearerToken }),
    workflowId: sessionStorage.getItem(StorageKeys.WORKFLOW_ID),
  });
};

async function dpaCheck(): Promise<CheckDpaConsentResponse> {
  return fetcher(Endpoints.DPA_CHECK, {
    method: 'POST',
    body: JSON.stringify({ consentDocumentName: DPA_DOCUMENT_NAME } as CheckDpaConsentRequest),
    workflowId: sessionStorage.getItem(StorageKeys.WORKFLOW_ID),
  });
}

async function createToken(
  clientCreateRequest: ClientCreateRequest,
  createTokenURL: string,
): Promise<ClientCreateResponse> {
  return fetcher(createTokenURL, {
    method: 'POST',
    body: JSON.stringify(clientCreateRequest),
    workflowId: sessionStorage.getItem(StorageKeys.WORKFLOW_ID),
  });
}

const getUploadUrl = async (fileName: string, accessModifier: FileAccessModifier) => {
  return fetcher(Endpoints.PREPARE_FILE_UPLOAD, {
    method: 'POST',
    body: JSON.stringify({ fileSuffix: fileName, fileAccessModifier: accessModifier }),
    workflowId: sessionStorage.getItem(StorageKeys.WORKFLOW_ID),
  });
};

const uploadFile = async (file: File, uploadUrl: string) => {
  const fileArray = await file.arrayBuffer();
  return fetch(uploadUrl, {
    method: 'PUT',
    body: fileArray,
    headers: {
      ...CommonHeaders,
      'Content-Type': 'application/octet-stream',
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response
          .json()
          .then((json) => json)
          .catch(() => null); //response with empty body
      }
      const error = await response.json().then((json) => json);
      return Promise.reject(error.error);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      return Promise.reject(error);
    });
};

export {
  icpMetadataSave,
  getIpRanges,
  saveOrgInfo,
  getAadConsentUrl,
  startTenantSetup,
  createTenantSetupCompleteRequest,
  sendTenantSetupCompleteRequest,
  getUploadUrl,
  uploadFile,
  dpaCheck,
  createToken,
};
