// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { useNavigateToNextStep } from './useNavigateToNextStep';
import * as api from '../../api/setupServices';
import { FileAccessModifier, type PrepareFileUploadResponse } from '../../api/types';
import type { AppError } from '../../errorHandler/errors';
import { SPANS_SETUP, tracer } from '../../utils/tracer';
import { STEPS } from '../constants';

const useOrganizationInfoSave = () => {
  const navigateToNextStep = useNavigateToNextStep();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return useMutation<any, AppError, { orgName: string; logoName: string; fileContents: File }>({
    mutationFn: async ({ orgName, logoName, fileContents }) => {
      let uploadLinks: PrepareFileUploadResponse;

      try {
        tracer.startSpan(SPANS_SETUP.get_upload_url);
        uploadLinks = await api.getUploadUrl(logoName, FileAccessModifier.PUBLIC);
        tracer.endSpan(SPANS_SETUP.get_upload_url);
      } catch (error) {
        tracer.endSpan(SPANS_SETUP.get_upload_url, { error });
        throw error;
      }

      try {
        tracer.startSpan(SPANS_SETUP.upload_file);
        await api.uploadFile(fileContents, uploadLinks.uploadUrl);
        tracer.endSpan(SPANS_SETUP.upload_file);
      } catch (error) {
        tracer.endSpan(SPANS_SETUP.upload_file, { error });
        throw error;
      }

      try {
        tracer.startSpan(SPANS_SETUP.save_org_info);
        await api.saveOrgInfo({
          logoUrl: uploadLinks.readUrl,
          orgName: orgName,
        });
        tracer.endSpan(SPANS_SETUP.save_org_info);
      } catch (error) {
        tracer.endSpan(SPANS_SETUP.save_org_info, { error });
        throw error;
      }
    },
    onSuccess: () => {
      navigateToNextStep(STEPS.SETUP_COMPLETE);
    },
    onError: (error) => {
      throw error;
    },
  });
};

export default useOrganizationInfoSave;
