import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { StorageKeys, SetupState } from '../../../api/constants';
import { getPortalUrl } from '../../../utils/utils';
import { STEPS } from '../../constants';
import { tracer, SPANS_SETUP } from '../../../utils/tracer';
import * as api from '../../../api/setupServices';
import { FatalError, type ApiError } from '../../../errorHandler/errors';
import { useNotifications } from '../../../errorHandler/context/Notifications';

const TRANSLATION_ROOT = `setup.${STEPS.SETUP_COMPLETE}.content`;

const SetupComplete = () => {
  const { emitError } = useNotifications();

  useEffect(() => {
    const setupState = sessionStorage.getItem(StorageKeys.TENANT_SETUP_STATE);
    console.log('[Setup Complete] Setup State: ', setupState);

    async function completeSetup() {
      tracer.startSpan(SPANS_SETUP.complete_setup);
      try {
        await api.sendTenantSetupCompleteRequest(false)
        sessionStorage.setItem(StorageKeys.TENANT_SETUP_STATE, SetupState.COMPLETED);
        tracer.endSpan(SPANS_SETUP.complete_setup);
        localStorage.clear();
        sessionStorage.clear();
      } catch (e) {
        tracer.endSpan(SPANS_SETUP.complete_setup, { error: e });
        // If the complete setup call fails, show a full page error.
        emitError(new FatalError(e as ApiError));
      }
    }

    if (setupState === SetupState.STARTED) {
      void completeSetup();
    }
  }, [emitError]);

  return (
    <>
      <p style={{ marginBottom: '8.13rem' }}>
        <Trans i18nKey={`${TRANSLATION_ROOT}.description`} />
        <a href={getPortalUrl()} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold' }}>
          access.imprivata.com
        </a>
        .
      </p>
      <p>
        <Trans i18nKey={`${TRANSLATION_ROOT}.description2`} />
      </p>
    </>
  );
};

export default SetupComplete;
